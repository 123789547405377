import clsx from 'clsx'
import * as React from 'react'
import { useSSRLayoutEffect } from '#app/utils/misc'
import  { type Image as ImageType } from '../../types/graphql.page.ts'

export default function Image({
	img,
	width,
	height,
	className,
	noWebp = false,
}: {
	noWebp?: boolean
	img: ImageType
	width?: number
	height?: number
	className?: string
}) {
	const src = `https://craft.settooncapital.com${
		img?.optimized ? img.optimized.src : img.url || ''
	}`
	const srcset = img?.optimized
		? img.optimized.srcset
				.split(', ')
				.map(src => `https://craft.settooncapital.com${src}`)
				.join(', ')
		: ''
	const srcsetWebp =
		img?.optimized && img.optimized.srcsetWebp
			? img.optimized.srcsetWebp
					.split(', ')
					.map(src => `https://craft.settooncapital.com${src}`)
					.join(', ')
			: ''

	return (
		<picture>
			{srcsetWebp && !noWebp && (
				<source srcSet={srcsetWebp} type="image/webp" />
			)}
			<source srcSet={srcset} type="image/jpg" />
			<img
				className={`${className}`}
				src={src}
				alt={img.alt || img.title}
				title={img.title}
				height={height}
				width={width}
			/>
		</picture>
	)
}

function BlurrableImage({
	img,
	blurDataUrl,
	...rest
}: {
	img: JSX.Element &
		React.ReactElement<React.ImgHTMLAttributes<HTMLImageElement>>
	blurDataUrl?: string
} & React.HTMLAttributes<HTMLDivElement>) {
	const [visible, setVisible] = React.useState(false)
	const jsImgElRef = React.useRef<HTMLImageElement>(null)

	// make this happen asap
	// if it's alrady loaded, don't bother fading it in.
	useSSRLayoutEffect(() => {
		if (jsImgElRef.current?.complete) setVisible(true)
	}, [])

	React.useEffect(() => {
		if (!jsImgElRef.current) return
		if (jsImgElRef.current.complete) return

		let current = true
		jsImgElRef.current.addEventListener('load', () => {
			if (!jsImgElRef.current || !current) return
			setTimeout(() => {
				setVisible(true)
			}, 0)
		})

		return () => {
			current = false
		}
	}, [])

	const jsImgEl = React.cloneElement(img, {
		ref: jsImgElRef,
		className: clsx(img.props.className, 'transition-opacity', {
			'opacity-0': !visible,
		}),
	})

	return (
		<div {...rest}>
			{blurDataUrl ? (
				<>
					<img
						key={blurDataUrl}
						src={blurDataUrl}
						className={img.props.className}
						alt={img.props.alt}
					/>
					<div className={clsx(img.props.className, 'backdrop-blur-xl')} />
				</>
			) : null}
			{jsImgEl}
			<noscript>{img}</noscript>
		</div>
	)
}

export { BlurrableImage }
